
body{
    background-image: url('./images/background.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;

}

.stepper-item {
  flex: 1;
}

.stepper-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.stepper-circle.active {
  background-color: #F9512C;
  box-shadow: 0 0 15px rgba(249, 81, 44, 0.5);
  color: #fff;
}
.stepper-circle.completed {
  background-color: #F9512C; /* Couleur différente pour les étapes complètes */
  box-shadow: 0 0 10px rgba(249, 81, 44, 0.5);
  color: #fff;
}
.stepper-circle.active:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgba(249, 81, 44, 0.1);
  border-radius: 50%;
}

p {
  font-size: 14px;
}
.container {
  max-width: 700px;
}

.form-box {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #F9512C;
}

.vh-100 {
  height: 100vh;
}

.shadow {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
