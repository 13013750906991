/* src/components/assets/Checklist.css */
.checklist {
    margin-top: 1px;
  }
  
  .list-group-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
    background-color: #f9f9f9;
  }
  
  .checklist-item-completed {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
  
  .checklist-item-in-progress {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  }
  
  .checklist-item-late {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  
  .checklist-icon {
    font-size: 1.2rem;
    margin-right: 10px;
  }
  
  .status-badge {
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  ul li{
    list-style: none;
  }