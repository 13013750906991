:root{
    --orang: #F9512C;
    --white: #ffff ;
    --grey:#f5f5f5 ;
    --black: #222 ;
    --black2:#999 ;
  }
  .orange{
    color: var(--orang);
    font-weight: 700;
  }

.property-card {
    flex: 0 0 30%; /* Ajustez la largeur pour que trois cartes soient visibles */
    margin-right: 10px; /* Espacement entre les cartes */
  }
.hide-scroll-bar {
    overflow-x: auto; /* Autoriser le défilement horizontal */
    scrollbar-width: none; /* Pour Firefox */
  }
  
  .hide-scroll-bar::-webkit-scrollbar {
    display: none; /* Pour Chrome, Safari et Opera */
  }
  .card-titl{
    font-size: 18px;
  }
    