@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: poppin;
}
:root{
  --orang: #F9512C;
  --white: #ffff ;
  --grey:#f5f5f5 ;
  --black: #222 ;
  --black2:#999 ;
}

body {
  min-height: 100vh;
  
}
.containere{
  position: relative !important;
  width: 100% !important;
}
.sidebar {
  position: fixed; 
  width: 300px;
  height: 100%;
  background: var(--white);
  border-left: 10px solid var(--orang);
  transition: 0.5s;
  overflow: hidden;
}
.sidbar .logos{
  margin-bottom: 10px;
}
.sidebar ul{
  position: relative;
  top: 10px;
  left: 0;
  width: 100%;
}
.sidebar ul li .text-color{
  position: relative;
  width: 300px;
  padding: 10px;
  padding-left: 2px;
  left: 0;
  list-style: none;
  border-left:none ;
  color: var(--black);
}

.sidebar ul li .text-color:hover {
  background: var(--orang);
  color: var(--white);
  border-left: 10px solid var(--orang);
  border-radius: 15px;
}
.sidebar ul li .text-color:active{
  background: var(--orang);
  color: var(--white);
  border-left: 10px solid var(--orang);
  border-radius: 15px;
}
.sidebar-icon {
  font-size: 1em;
}
.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0); /* Always open on larger screens */
  }
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.contente{
  position:relative;
  margin-left: 280px;
}
.contente .header {
  background-color: #f8f9fa;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-section {
  display: flex;
  align-items: center;
}

.user-info img {
  width: 40px;
  height: 40px;
}

.card {
  margin-bottom: 20px;
}
.btn-custom {
  color: var(--white);
  background-color: var(--orang);
  border-color: var(--orang);
}

.btn-custom:hover {
  background-color: var(--orang);
  opacity: 0.9;
}