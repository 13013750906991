body{
    background-image: url('./images/background.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;

}

.login-container {
    text-align: center;
  }
  
  .login-container img {
    width: 150px; /* Ajuste selon la taille du logo */
  }
  
  .btn-primary {
    background-color: #f56c42; /* Couleur personnalisée pour le bouton */
    border-color: #f56c42;
  }
  
  .btn-primary:hover {
    background-color: #e55a36; /* Couleur du bouton au survol */
    border-color: #e55a36;
  }
  