.card {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.card-body {
  padding: 1.5rem;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid;
}

.triangle-red {
  border-bottom-color: #dc3545;
}

.triangle-yellow {
  border-bottom-color: #ffc107;
}

.triangle-green {
  border-bottom-color: #28a745;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
